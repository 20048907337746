import React from 'react';
import {
  faCalendarAlt,
  faFile,
  faFilePdf
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SEO from '../../components/Seo';
import ContentContainer from '../common/ContentContainer';
import ContentfulContent from '../common/ContentfulContent';
import Layout from '../Layout';
import './../layout.css';
import './LayoutCommon.css';
import { getEventDateRange } from './../../util';
import { Link } from 'gatsby';
import Button from '../common/Button';

type Offering = {
  title: string;
  startDate?: string;
  endDate?: string;
  slug?: string;
  download?: {
    file: {
      contentType: string;
      url: string;
    };
  };
};
interface OfferingLinkProps {
  offering: Offering;
  children: React.ReactChild;
}

const isEvent = (offering: Offering): boolean =>
  !!offering.startDate && !!offering.endDate;

const OfferingLink: React.FC<OfferingLinkProps> = props => {
  if (isEvent(props.offering)) {
    return <Link to={`/events/${props.offering.slug}`}>{props.children}</Link>;
  } else {
    return (
      <a href={props.offering?.download?.file?.url} target="_blank">
        {props.children}
      </a>
    );
  }
};

interface OfferingsProps {
  offering: Offering;
}

const Offering: React.FC<OfferingsProps> = props => {
  return (
    <div className="text-gray-700 py-4 border-b">
      <OfferingLink offering={props.offering}>
        <div className="flex items-center mb-2 text-primary">
          <FontAwesomeIcon
            className="mr-4"
            icon={
              isEvent(props.offering)
                ? faCalendarAlt
                : props.offering?.download?.file?.contentType ===
                  'application/pdf'
                ? faFilePdf
                : faFile
            }
          />
          <div className="flex flex-col">
            <p className="font-semibold sans-serif hover:underline">
              {props.offering?.title}
            </p>
            {props.offering.startDate && props.offering.endDate && (
              <p className="text-sm text-gray-600">
                {getEventDateRange(
                  new Date(props.offering.startDate),
                  new Date(props.offering.endDate)
                )}
              </p>
            )}
          </div>
        </div>
      </OfferingLink>
    </div>
  );
};

interface PageContentProps {
  pageData: any;
}

const PageContent = (props: PageContentProps) => {
  return (
    <>
      {props.pageData?.featuredImage && (
        <div
          className="rounded-t-lg h-96 w-full bg-cover"
          style={{
            backgroundImage: props.pageData?.featuredImage
              ? `url(${props.pageData?.featuredImage?.fluid?.src})`
              : ''
          }}
        ></div>
      )}
      <section className="bg-white rounded-lg shadow-lg p-12">
        <h1 className="font-bold text-3xl text-gray-800">
          {props.pageData?.title}
        </h1>
        {/* {props.pageData?.showBuyNowButton && (
          <div className="mt-4">
            <a href={props.pageData?.purchaseLink}>
              <Button text="Purchase Now" />
            </a>
          </div>
        )} */}
        <section className="mt-10 content-body">
          <ContentfulContent content={props.pageData?.body?.body} />
        </section>
      </section>
    </>
  );
};

const UpcomingEvent = (props: any) => {
  return (
    <Link to={`/events/${props.upcomingEvent.slug}`}>
      <div className="bg-white rounded-lg shadow mt-4 p-4 cursor-pointer flex">
        <div className="ml-4">
          <p className="text-sm text-gray-700 font-semibold sans-serif">
            {props.upcomingEvent.title}
          </p>
          <p className="text-xs text-gray-500 uppercase mt-1">
            {getEventDateRange(
              new Date(props.upcomingEvent.startDate),
              new Date(props.upcomingEvent.endDate)
            )}
          </p>
        </div>
      </div>
    </Link>
  );
};

const UpcomingEvents = (props: any) => {
  return (
    <>
      {props.upcomingEvents.map((event: Event, i: number) => {
        return <UpcomingEvent key={i} upcomingEvent={event} />;
      })}
    </>
  );
};

const PageLayout = ({ pageContext }: any) => {
  return (
    <Layout>
      <SEO title={pageContext?.pageData?.title} />
      <ContentContainer>
        <div className="flex flex-col lg:flex-row">
          <div className="w-full lg:w-2/3 h-full mr-4">
            <PageContent pageData={pageContext?.pageData} />
          </div>
          <div className="flex flex-col w-full lg:w-1/3 h-full lg:ml-4">
            <div>
              {pageContext?.pageData?.offerings?.length && (
                <div className="mb-4 lg:mb-8">
                  <aside className="bg-white rounded-lg shadow-md p-8 mt-4 lg:mt-0">
                    <p className="text-2xl font-bold text-gray-600 sans-serif mb-4">
                      Offerings
                    </p>
                    <>
                      {pageContext?.pageData?.offerings?.map(
                        (o: any, i: number) => (
                          <Offering offering={o} key={i} />
                        )
                      )}
                    </>
                  </aside>
                </div>
              )}
            </div>
            {pageContext.pageData.slug ===
            'special-topic-institutes-2022-2023' ? (
              <div className="w-full mt-6 lg:mt-0">
                <h2 className="text-primary mb-4 font-bold text-xl">
                  Upcoming Events
                </h2>
                <UpcomingEvents upcomingEvents={pageContext.upcomingEvents} />
              </div>
            ) : null}
          </div>
        </div>
      </ContentContainer>
    </Layout>
  );
};

export default PageLayout;
